import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import loadable from "@loadable/component"
import {
  Button,
  Title,
  Section,
  Text,
  SectionWrapper,
  HeroSection,
} from "../components/core";
import { useMixpanel } from "../context/MixpanelContext";

import DNOLarge from "../assets/images/dno_large.svg";
import ENOLarge from "../assets/images/eno_large.svg";
import WorkmenLarge from "../assets/images/workmens_large.svg";
import LiabilityLarge from "../assets/images/liability_large.svg";
import useHindiLangFont from "../hooks/useHindiLangFont";
import { LeadFormProvider } from "../context/LeadFormContext";
import { useTranslation } from "react-i18next";

const LeadForm = loadable(() => import("./LeadForm"));

const fireProductWrapperStyles = {
  paddingBottom: "0",
  marginBottom: "0"
}

const PolicyHeader = ({ title, acronym, content, subcontent, showQuoteCTA=true }) => {
  const {t} = useTranslation('common')
  const {hindiBlack, hindiBold, hindiRegular} = useHindiLangFont()
  const mixpanel = useMixpanel();
  let icon = null;
  let mxEventName = "";

  const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);

  const handleQuoteModalOpen = () => {
    if (mxEventName) mixpanel.track(mxEventName);
    setIsQuoteModalOpen(true);
  };

  const handleQuoteModalClose = () => {
    setIsQuoteModalOpen(false);
  };

  switch (acronym) {
    case "BSUS": {
      icon = (
        <div className="policy-art">
          <StaticImage src={"../assets/images/bsus_large.webp"} alt="bsus-large" />
        </div>
      )
      mxEventName = "Clicked-Top - 'Get a Quote' - BSUS LP";
      break;
    }
    case "BLUS": {
      icon = (
        <div className="policy-art">
          <StaticImage src={"../assets/images/blus_large.webp"} alt="blus-large" />
        </div>
      );
      mxEventName = "Clicked-Top - 'Get a Quote' - BLUS LP";
      break;
    }
    case "SFSP": {
      icon = (
        <div className="policy-art">
          <StaticImage src={"../assets/images/sfsp_large.webp"} alt="blus-large" />
        </div>
      );
      mxEventName = "Clicked-Top - 'Get a Quote' - SFSP LP";
      break;
    }
    case "DNO": {
      icon = <DNOLarge className="policy-art" />;
      mxEventName = "Clicked-Top - 'Get a Quote' - D&O LP";
      break;
    }
    case "ENO": {
      icon = <ENOLarge className="policy-art" />;
      mxEventName = "Clicked-Top - 'Get a Quote' - E&O LP";
      break;
    }
    case "WORKMEN": {
      icon = <WorkmenLarge className="policy-art" />;
      mxEventName = "Clicked-Top- 'Get a Quote' - WC LP";
      break;
    }
    case "LIABILITY": {
      icon = <LiabilityLarge className="policy-art" />;
      mxEventName = "Clicked-Top - 'Get a Quote' - PL LP";
      break;
    }
    default: {
      icon = (
        <div className="policy-art">
          <StaticImage src={"../assets/images/bsus_large.webp"} alt="bsus-large" />
        </div>
      )
      mxEventName = "Clicked-Top - 'Get a Quote' - BSUS LP";
    }
  }
  const isFireProduct = ["BSUS", "BLUS", "SFSP"].includes(acronym);
  return (
    <>
      <LeadFormProvider>
        {isQuoteModalOpen ? <LeadForm onModalClose={handleQuoteModalClose} /> : null}
      </LeadFormProvider>
      <Section background="#ffffff">
        <SectionWrapper style={isFireProduct ? fireProductWrapperStyles : {}}>
          <HeroSection>
            <Title fontSize="60px" style={{ marginTop: "65px" }} className={hindiBlack}>
              {title}
            </Title>
            <Container>
              <HeaderContainer>
                <Text fontSize="16px" mfontSize="14px" className={hindiRegular}>
                  {content}
                </Text>
                <HeroContainerMobile>{icon}</HeroContainerMobile>
                <Text fontSize="16px" mfontSize="14px" className={`sub-content ${hindiRegular}`}>
                  {subcontent}
                </Text>
                {showQuoteCTA && <CTASection className="display-flex align-items-to-center">
                  <Button wide label={t('CTALabels.GetAQuote')} onClick={handleQuoteModalOpen} className={hindiBold} />
                </CTASection>}
              </HeaderContainer>
              <HeroContainer>{icon}</HeroContainer>
            </Container>
          </HeroSection>
        </SectionWrapper>
      </Section>
    </>
  );
};

const CTASection = styled.div`
  .brochure-download-btn {
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(255, 147, 79, 0.12);
    color: #ff934f;
    border: 1px solid #f45e00;
  }
  &:hover .brochure-download-btn {
    /* darken the shadow by 4 px */
    box-shadow: 0px 4px 8px rgba(255, 147, 79, 0.7);
  }
  @media (min-width: 768px) {
    .brochure-download-btn {
      margin-left: 1rem !important;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    .brochure-download-btn {
      margin-top: 1rem !important;
    }
  }
`;

const HeroContainer = styled.div`
  .policy-art {
    width: 442px;
    height: 450px;
    @media screen and (max-width: 768px) {
      width: 0px;
      height: 0px;
      /* visibility: hidden; */
    }
  }
`;

const HeroContainerMobile = styled.div`
  .policy-art {
    width: 0px;
    height: 0px;
    /* visibility: hidden; */

    @media screen and (max-width: 768px) {
      /* visibility: visible; */
      margin-right: auto;
      margin-left: auto;
      width: 230px;
      height: 250px;
    }
  }
`;

const HeaderContainer = styled.div`
  width: 65%;

  .sub-content {
    margin: 20px 0px 50px 0px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    button {
      margin-left: auto;
      margin-right: auto;
    }

    .sub-content {
      margin: 30px 0px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;

  .space-left {
    padding-left: 35px;
  }

  .space-right {
    padding-right: 35px;
  }
`;

export default PolicyHeader;
